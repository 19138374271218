$breakpoint: (
  'mobile': 'screen and (max-width: 700px)',
  'tablet': 'screen and (max-width: 1000px)',
  'desktop': 'screen and (max-width: 1200px)',
) !default;

@mixin breakpoint($window-size: desktop) {
  @media #{map-get($breakpoint, $window-size)} {
    @content;
  }
}

html {
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-size($font-size, $line-height: 30) {
  $rem: $font-size / 15;
  $line-height-size: $line-height / $font-size;
  font-size: #{$font-size}px;
  font-size: #{$rem}rem;
  line-height: $line-height-size;
}

@mixin thema-color ($color: #2a83a2) {
    color: $color;
}

@mixin text-color ($color: #787878) {
    color: $color;
}

@mixin link-color ($color: #ff6f61) {
    color: $color;
}