@import "config";

* {
	margin: 0;
	padding: 0;
	list-style-type: none;
	border: none;
	box-sizing: border-box;
}

::selection {
	background-color: #2a83a2;
	color: #FFF;
}

@font-face {
    font-family: 'Oswald-DemiBold';
    src: url('../font/Oswald-DemiBold.woff') format('woff'),
         url('../font/Oswald-DemiBold.ttf')  format('truetype');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('../font/Lato-Regular.woff') format('woff'),
         url('../font/Lato-Regular.ttf')  format('truetype');
}

@font-face {
    font-family: 'Lato-BoldItalic';
    src: url('../font/Lato-BoldItalic.woff') format('woff'),
         url('../font/Lato-BoldItalic.ttf')  format('truetype');
}

@font-face {
    font-family: 'GenEiKoburiMinT-R';
    src: url('../font/GenEiKoburiMinT-R.woff') format('woff'),
         url('../font/GenEiKoburiMinT-R.ttf')  format('truetype');
}

@font-face {
    font-family: 'NotoMedium';
    src: url('../font/NotoMedium.woff') format('woff'),
         url('../font/NotoMedium.ttf')  format('truetype');
}

@font-face {
    font-family: 'NotoRegular';
    src: url('../font/NotoRegular.woff') format('woff'),
         url('../font/NotoRegular.ttf')  format('truetype');
}

@font-face {
    font-family: 'NotoDemiLight';
    src: url('../font/NotoDemiLight.woff') format('woff'),
         url('../font/NotoDemiLight.ttf')  format('truetype');
}

@font-face {
    font-family: 'NotoLight';
    src: url('../font/NotoLight.woff') format('woff'),
         url('../font/NotoLight.ttf')  format('truetype');
}

@font-face {
    font-family: 'GenSenMaruGothicJP-Medium';
    src: url('../font/GenSenMaruGothicJP-Medium.woff') format('woff'),
         url('../font/GenSenMaruGothicJP-Medium.ttf')  format('truetype');
}

body {
	min-height: 100%;
	@include font-size(16);
	font-family: 'NotoDemiLight';
	@include text-color();
	text-align: justify;
	box-sizing: border-box;
    padding: 0 10px 10px 10px;
    
    @include breakpoint(tablet) {
    @include font-size(14);
    padding: 0 5px 5px 5px;
    }
}

a:link {
	@include text-color();
	text-decoration: none;
}

a:hover {
	@include text-color();
	text-decoration: none;
}

a:action {
	@include text-color();
	text-decoration: none;
}

a:visited {
	@include text-color();
	text-decoration: none;
}

img {
    display: block;
}

h1,h2,h3,h4,h5,h6 {
	font-family: 'NotoDemiLight';
	@include font-size(16);
	font-weight: normal;
    @include text-color();
    
    @include breakpoint(tablet) {
    @include font-size(14);
    }
}


//ie edge用コメント
#notice {
    display: none;
}

_:-ms-lang(x), #notice {
    property: value;
    display: block;
    font-size: 120%;
    font-weight: bold;
    color: #EC5153;
    text-align: center;
    margin: 0 0 30px 0;
}


/*ヘッダー*/
header {
    width: 100%;
    padding: 30px 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    font-family: 'NotoMedium';
    
    @include breakpoint(tablet) {
    padding: 20px 5px;
    }
    
    p {
    max-width: 250px;
    
    @include breakpoint(tablet) {
    max-width: 200px;
    }
    
    img {
    width: 100%;
    }
    }
    
    #toggle {
    display: none;
    
    @include breakpoint(mobile) {
    width: 30px;
    display: block;
    z-index: 100;
    }
    }
    
    .hide {
    opacity: 1;
    visibility: visible;
    
    @include breakpoint(mobile) {
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    }
    }
    
    #trigger {
    position: relative;
    width: 30px;
    height: 20px;
    display: block;
    transition: 0.3s;
    
    span {
    width: 100%;
    height: 4px;
    display: block;
    position: absolute;
    left: 0;
    transition: 0.3s;
    background-color: #2a83a2;
    }
    
    span:nth-of-type(1) {
    top: 0;
    }
    
    span:nth-of-type(2) {
    top: 8px;
    }
    
    span:nth-of-type(2)::after {
    width: 100%;
    height: 4px;
    position: absolute;
    content:  '';
    background-color: #2a83a2;
    transition: 0.2s;
    }
    
    span:nth-of-type(3) {
    bottom: 0;
    }
    }
    
#trigger.active span:nth-of-type(1) {
    transform: translateY(8px) scale(0);
    }
    
#trigger.active span:nth-of-type(2) {
    transform: rotate(-45deg);
    }
    
#trigger.active span:nth-of-type(3) {
    transform: translateY(-8px) rotate(45deg);
    }
    
.animation {
    transition: 0.3s;
    }
    
nav {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    position: relative;
    z-index: 10;
    
    @include breakpoint(mobile) {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    }
    
ul {
    display: -webkit-flex;
    display: flex;
    @include font-size(14,14);
    
    @include breakpoint(tablet) {
    @include font-size(13,13);
    padding: 15px 5px;
    }
    
    @include breakpoint(mobile) {
    padding: 0 0 0 0;
    margin: 0 auto;
    -webkit-flex-direction: column;
    flex-direction: column;
    text-align: center;
    }
    
li {
    margin: 0 50px 0 0;
    
    a {
    transition: 0.2s;
    position: relative;
    }
    
    a:hover {
    color: #ff6f61;
    }
    
    a::after {
    width: 100%;
	height: 1px;
	position: absolute;
	top: 100%;
	left: 0;
	background: #ff6f61;
	content: '';
	opacity: 0;
	transition: height 0.2s, opacity 0.2s, transform 0.2s;
	transform: translateY(-10px);
    }
    
    a:hover::after,
    a:focus::after {
	height: 1px;
	opacity: 1;
	transform: translateY(7px);
    
    @include breakpoint(mobile) {
    height: 0;
    }
    }
    
    @include breakpoint(tablet) {
    margin: 0 25px 0 0;
    }
    
    @include breakpoint(mobile) {
    margin: 0 0 0 0;
    line-height: 3;
    }
    }
    
li:last-child {
    margin: 0 0 0 0;
    }
    }
    }
}

//TODO:共通ページ見出し
#title {
    font-family: 'Lato-BoldItalic';
    @include font-size(24);
    @include thema-color();
    text-align: center;
    letter-spacing: 0.07em;
    line-height: 1;
    padding: 40px 0;
    margin: 0 0 100px 0;
    background-color: #f5f5f5;
    
    @include breakpoint(mobile) {
    font-size: 120%;
    padding: 20px 0;
    margin: 0 0 50px 0;
    }
}

//TODO:共通コンテナ
#info {
    width: 100%;
    padding: 0 40px;
    margin: 0 0 100px 0;
    
    @include breakpoint(tablet) {
    padding: 0 10px;
    }
    
    @include breakpoint(mobile) {
    margin: 0 0 50px 0;
    }
}

//TODO:フッター
footer {
    width: 100%;
    color: #fff;
    padding:14px 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    background-color: #323232;
    
    @include breakpoint(mobile) {
    padding: 10px 0;
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
    }
    
    p {
    font-family: 'Lato-Regular',sans-serif;
    @include font-size(12,12);
    letter-spacing: 0.03em;
    
    @include breakpoint(mobile) {
    text-align: center;
    }
    }
    
    nav {
    @include font-size(12,12);
    
    ul {
    display: -webkit-flex;
    display: flex;
    
    @include breakpoint(mobile) {
    -webkit-justify-content: center;
    ustify-content: center;
    margin: 0 0 10px 0;
    }
    
    li {
    margin: 0 30px 0 0;
    
    @include breakpoint(tablet) {
    margin: 0 15px 0 0;
    }
    
    a {
    color: #fff !important;
    transition: 0.2s;
    }
    
    a:hover {
    color: #ff6f61 !important;
    }
    }
    
    li:last-child {
    margin: 0 0 0 0;
    }
    }
    }
}